export const COMMENT = "COMMENT";
export const BUTTON = "BUTTON";
export const IMAGE_LOAD = "IMAGE_LOAD";
export const SHOW_GROUP_BUTTON = "SHOW_GROUP_BUTTON";
export const GROUP_SALES_TAG = "GROUP_SALES_TAG";
export const PROPERTY_DATABASE_CELL = "PROPERTY_DATABASE_CELL";

export const imageStyles = {
  width: "100%",
  objectFit: "cover",
  height: "100%",
};

export const dateFormatHandler = (date) => {
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const month = date.toLocaleString("en-US", { month: "2-digit" });
  const year = date.toLocaleString("en-US", { year: "numeric" });
  return `${month}/${day}/${year}`;
};

export const dateFormatForListings = (date) => {
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const month = date.toLocaleString("en-US", { month: "2-digit" });
  const year = date.toLocaleString("en-US", { year: "numeric" });
  return `${year}-${month}-${day}`;
};

export const groupSalesValidator = (record, applicationNumbers) => {
  let groupSalesIsValid = false;
  if (record.sales.length > 0) {
    groupSalesIsValid = applicationNumbers?.some(
      (applicationNumber) =>
        applicationNumber === record.sales[0].application_number
    );
  }
  return groupSalesIsValid;
};

export const latestListingsFinder = (listingsRecord) => {
  let latestData = listingsRecord[0];
  if (listingsRecord.length > 1) {
    let latestDate = new Date(listingsRecord[0].date_added);
    for (let i = 0; i < listingsRecord.length; i++) {
      if (latestDate < new Date(listingsRecord[i].date_added)) {
        latestDate = new Date(listingsRecord[i].date_added);
        latestData = listingsRecord[i];
      }
    }
    return latestData;
  } else {
    return latestData;
  }
};

export const latestSalesFinder = (salesRecord) => {
  let latestData = salesRecord[0];
  if (salesRecord.length > 1) {
    let latestDate = new Date(salesRecord[0].registration_date);
    for (let i = 0; i < salesRecord.length; i++) {
      if (latestDate < new Date(salesRecord[i].registration_date)) {
        latestDate = new Date(salesRecord[i].registration_date);
        latestData = salesRecord[i];
      }
    }
    return latestData;
  } else {
    return latestData;
  }
};
