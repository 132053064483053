import {
  GET_MANUAL_REVIEW_DATA,
  GET_MANUAL_REVIEW_DATA_SUCCESS,
  GET_MANUAL_REVIEW_DATA_FAILED,
  SET_MANUAL_REVIEW_FILTER_MAX_VALUES,
  GET_MANUAL_REVIEW_FILTER_MAX_VALUES,
  GET_MANUAL_REVIEW_FILTER_MAX_VALUES_FAILED,
  MANUAL_REVIEW_FILTERED_DATA,
  APPLY_MANUAL_REIVEW_FILTERS,
  RESET_MANUAL_REVIEW_FILTERS,
  CHANGE_MANUAL_REVIEW_FILTERS_DATA,
  SET_MANUAL_REIVEW_FILTER_LOADING,
  PREVENT_MANUAL_REVIEW_APPLYING_FILTERS,
  GET_MANUAL_REVIEW_DATA_BY_ID,
  GET_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
  GET_MANUAL_REVIEW_DATA_BY_ID_FAILED,
  SET_MANUAL_REVIEW_DATA_BY_ID_NULL,
  SET_MANUAL_REIVEW_PAGINATION,
  SHOW_DELETE_MODAL,
  HIDE_DELETE_MODAL,
  DELETE_MANUAL_REVIEW_DATA_BY_ID,
  DELETE_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
  DELETE_MANUAL_REVIEW_DATA_BY_ID_FAILED,
  HIDE_DELETE_ERROR_MESSAGE,
  CLEAR_MANUAL_REVIEW_DATA_BY_ID_SUCCESS,
  MAKE_MANUAL_REVIEW_DATA_NULL,
} from "../constants/ManualReview";

const initState = {
  manualReviewData: null,
  manualReviewDataLoading: false,
  manualReviewDataErrorMessage: null,
  manualReviewDataById: null,
  manualReviewDataByIdLoading: false,
  manualReviewDataByIdErrorMessage: null,
  manualReviewFiltersLoading: false,
  manualReviewFilteredData: null,
  manualReviewFiltersMaxValues: [],
  manualReviewfilterResetMessage: false,
  applyManualReviewFiltersIsValid: false,
  manualReviewDeleteModalIsValid: false,
  manualReviewdeleteDataByIdLoading: false,
  manualReviewdeleteSuccess: false,
  manualReviewdeleteSuccessMessage: null,
  manualReviewdeleteError: false,
  manualReviewdeleteErrorMessage: null,
  manualReviewPagniation: { current: 1, pageSize: 10 },
  manualReviewFiltersData: {
    cities: [],
    price: [0, null],
    bedrooms: [],
    bathrooms: [],
    minDate: new Date(),
    maxDate: new Date(),
    propertyType: [],
    availability: "",
    status: [],
    arv: [0, null],
    soldInPast: false,
    undefinedDataUnits: false,
  },
};

const getManualReviewFilterValues = (data) => {
  return {
    cities: [],
    propertyType: [],
    availability: "",
    status: [],
    price: [0, Number(data[0])],
    maxDate: new Date(data[1]),
    minDate: new Date(data[2]),
    bedrooms: [],
    bathrooms: [],
    arv: [0, 3000000],
    importedToPropertiesDatabase: false,
    undefinedDataUnits: false,
  };
};

const manualReview = (state = initState, action) => {
  switch (action.type) {
    case GET_MANUAL_REVIEW_DATA:
      return {
        ...state,
        manualReviewDataLoading: true,
      };
    case GET_MANUAL_REVIEW_DATA_SUCCESS:
      return {
        ...state,
        manualReviewData: action.data,
        manualReviewDataLoading: false,
        applyManualReviewFiltersIsValid: true,
      };
    case GET_MANUAL_REVIEW_DATA_FAILED:
      return {
        ...state,
        manualReviewDataLoading: false,
        manualReviewDataErrorMessage: action.message,
      };
    case GET_MANUAL_REVIEW_DATA_BY_ID:
      return {
        ...state,
        manualReviewDataByIdLoading: true,
      };
    case GET_MANUAL_REVIEW_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        manualReviewDataByIdLoading: false,
        manualReviewDataById: action.data,
      };
    case GET_MANUAL_REVIEW_DATA_BY_ID_FAILED:
      return {
        ...state,
        manualReviewDataByIdLoading: false,
        manualReviewDataByIdErrorMessage: action.message,
      };
    case SET_MANUAL_REVIEW_DATA_BY_ID_NULL:
      return {
        ...state,
        manualReviewDataById: null,
        manualReviewDataByIdErrorMessage: null,
      };
    case GET_MANUAL_REVIEW_FILTER_MAX_VALUES:
      return {
        ...state,
        manualReviewFiltersLoading: true,
      };
    case SET_MANUAL_REVIEW_FILTER_MAX_VALUES:
      return {
        ...state,
        manualReviewFiltersLoading: false,
        manualReviewFiltersMaxValues: action.data,
        manualReviewFiltersData: getManualReviewFilterValues(action.data),
      };
    case GET_MANUAL_REVIEW_FILTER_MAX_VALUES_FAILED:
      return {
        ...state,
        manualReviewFiltersLoading: false,
        manualReviewFiltersMaxValues: [],
        manualReviewfilterResetMessage: true,
      };
    case APPLY_MANUAL_REIVEW_FILTERS:
      return {
        ...state,
        manualReviewDataLoading: true,
      };
    case MANUAL_REVIEW_FILTERED_DATA:
      return {
        ...state,
        manualReviewDataLoading: false,
        manualReviewFilteredData: action.data,
      };
    case RESET_MANUAL_REVIEW_FILTERS:
      return {
        ...state,
        manualReviewFiltersLoading: false,
        manualReviewFiltersData: getManualReviewFilterValues(action.data),
      };
    case CHANGE_MANUAL_REVIEW_FILTERS_DATA:
      return {
        ...state,
        manualReviewFiltersData: action.data,
        applyManualReviewFiltersIsValid: true,
      };
    case SET_MANUAL_REIVEW_FILTER_LOADING:
      return {
        ...state,
        manualReviewFiltersLoading: true,
        applyManualReviewFiltersIsValid: true,
      };
    case PREVENT_MANUAL_REVIEW_APPLYING_FILTERS:
      return {
        ...state,
        applyManualReviewFiltersIsValid: false,
      };
    case SET_MANUAL_REIVEW_PAGINATION:
      return {
        ...state,
        manualReviewPagniation: action.data,
      };
    case SHOW_DELETE_MODAL:
      return {
        ...state,
        manualReviewDeleteModalIsValid: true,
      };
    case HIDE_DELETE_MODAL:
      return {
        ...state,
        manualReviewDeleteModalIsValid: false,
      };
    case DELETE_MANUAL_REVIEW_DATA_BY_ID:
      return {
        ...state,
        manualReviewdeleteDataByIdLoading: true,
      };
    case DELETE_MANUAL_REVIEW_DATA_BY_ID_FAILED:
      return {
        ...state,
        manualReviewdeleteError: true,
        manualReviewdeleteDataByIdLoading: false,
        manualReviewdeleteErrorMessage: action.message,
      };
    case DELETE_MANUAL_REVIEW_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        manualReviewdeleteSuccess: true,
        manualReviewdeleteSuccessMessage: action.message,
        manualReviewdeleteDataByIdLoading: false,
        manualReviewData: null,
      };
    case CLEAR_MANUAL_REVIEW_DATA_BY_ID_SUCCESS:
      return {
        ...state,
        manualReviewdeleteSuccess: false,
        manualReviewdeleteSuccessMessage: null,
      };
    case HIDE_DELETE_ERROR_MESSAGE:
      return {
        ...state,
        manualReviewdeleteError: false,
        manualReviewdeleteErrorMessage: null,
      };
    case MAKE_MANUAL_REVIEW_DATA_NULL:
      return {
        ...state,
        manualReviewData: null,
        manualReviewDataById: null,
      };

    default:
      return state;
  }
};
export default manualReview;
